import React from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./index.css";
import { startOfDay, endOfDay } from "date-fns";

interface DayProps {
  startDate: Date | null;
  setStartDate: React.Dispatch<React.SetStateAction<Date | null>>;
  setEndDate: React.Dispatch<React.SetStateAction<Date | null>>;
  className: string;
}

export default function DayPicker({
  startDate,
  setStartDate,
  setEndDate,
  className,
}: DayProps) {
  return (
    <div>
      <DatePicker
        className={className}
        placeholderText="Select a date"
        selected={startDate}
        onChange={(date: Date | null) => {
          if (date) {
            setStartDate(startOfDay(date));
            setEndDate(endOfDay(date));
          }
        }}
        dateFormat="MMMM d, yyyy"
      />
    </div>
  );
}
